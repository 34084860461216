import { LabelHTMLAttributes } from 'react';

export default function InputLabel({ value, required, className = '', children, ...props }: LabelHTMLAttributes<HTMLLabelElement> & { value?: string, required?: boolean }) {
    return (
        <label {...props} className={` ` + className}>
            {value ? value : children}
            {required && (
                <span className={'color-primary font-bold'}>&nbsp;*</span>
            )}
        </label>
    );
}
